"use client";

import { TopAdProvider } from "@/components/TopAd/top-ad.context";
import { type ReactNode, useState } from "react";
type TopAdProviderContainerProps = {
  readonly children: ReactNode;
};
export function TopAdProviderContainer({
  children
}: TopAdProviderContainerProps) {
  const [isTopBannerHidden, setIsTopBannerHidden] = useState<boolean | null>(null);
  return <TopAdProvider value={{
    isHidden: isTopBannerHidden,
    setIsHidden: setIsTopBannerHidden
  }} data-sentry-element="TopAdProvider" data-sentry-component="TopAdProviderContainer" data-sentry-source-file="TopAdProviderContainer.component.tsx">
      {children}
    </TopAdProvider>;
}